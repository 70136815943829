import { Injectable } from '@angular/core';
import {Restangular} from 'ngx-restangular';
import {AdditionalApproval} from '../models/additional-approval.model';

@Injectable({
  providedIn: 'root'
})
export class ReferringService {

  constructor(
    private restangular: Restangular,
  ) { }

  getReferringApprovalList(data) {
    return this.restangular.one('referring-approval/paginated-list').customPOST(data);
  }

  saveReferringApprovalForm(data) {
    return this.restangular.one('referring-approval/save').customPOST(data);
  }
  getReferringApprovalData(id) {
    return this.restangular.one('referring-approval').customGET('show', {id: id});
  }

  getReferringApprovalByProject(projectId ) {
    return this.restangular.one('referring-approval').customGET('refer-list', {projectId : projectId });
  }

  saveAdditionalReferringApproval(additionalApproval: AdditionalApproval) {
    return this.restangular.one('referring-approval/save-additional-refer-decision').customPOST(additionalApproval);
  }
}
